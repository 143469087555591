<template>
    <v-container>
        <v-row class="mb-5">
            <v-col cols="12" md="12">
                <p class="text-h3 font-weight-bold">Aviso de Privacidad</p>
                <p class="text-body-2 text-justify">El presente Aviso de Privacidad tiene como fin, informarle sobre el
                    tratamiento que se le da a sus datos
                    personales cuando estos son recabados, utilizados, almacenados y/o transferidos por Eudania SA de CV, y
                    aplicará para todos los productos, servicios, programas, herramientas tecnológicas, y/o sitios web, etc.
                    que preste Eudania SA de CV y la aplicación Eudania SP Residentes.
                </p>
                <p class="text-h6 font-weight-medium">Responsable del tratamiento de sus datos personales:</p>
                <p class="text-body-2 text-justify">Eudania SA de CV y la aplicación Eudania SP Residentes está comprometido con la salvaguarda e integridad de
                    esta información en los negocios
                    con nuestros clientes, empleados y socios comerciales, por lo que desde el año 2007 cuenta con una
                    política corporativa en materia de protección de datos, en la cual se contemplan medidas de seguridad y
                    controles para proteger la información y la cual ha sido actualizada de conformidad con los
                    requerimientos que exigen la regulación local.
                </p>
                <p class="text-body-2 text-justify">De acuerdo a lo Previsto en la “Ley Federal de Protección de Datos
                    Personales” Eudania SA de CV y la aplicación Eudania SP Residentes declara
                    ser una empresa legalmente constituida de conformidad con las leyes mexicanas con domicilio en Avenida
                    Insurgentes Sur 1863 301 B Col. Guadalupe INN México, Ciudad de México CP 01020 y que será responsable
                    sobre el tratamiento que le dé a sus datos personales
                </p>



                <p class="text-h6 font-weight-medium"> Finalidades y Uso de Sus Datos Personales</p>

                <p class="text-body-2 text-justify">Eudania SA de CV y la aplicación Eudania SP Residentes recaba, utiliza, almacena y procesa sus datos
                    personales para poder proporcionar
                    el servicio de agenda de visitas y tambien proveer información acerca de nuestros servicios, propuestas
                    comerciales y datos estadísticos.</p>

                <p class="text-h6 font-weight-medium">Recolección de información</p>
                <p class="text-body-2 text-justify">La Aplicación puede recopilar ciertos tipos de información, incluyendo:
                </p>
                <ul>
                    <li><strong>Información de geolocalización:</strong> Para proporcionar ciertas funciones, la Aplicación
                        puede requerir acceso a la información de geolocalización de tu dispositivo.</li>
                    <li><strong>Acceso a WhatsApp: </strong>La Aplicación puede solicitar acceso a tus contactos de WhatsApp
                        para proporcionar ciertas funciones.</li>
                    <li><strong>Acceso a la galería de fotos: </strong>• La Aplicación puede solicitar acceso a tu galería
                        de fotos para proporcionar ciertas funciones.</li>
                </ul>

                <p class="text-h6 font-weight-medium">Seguridad de los datos personales</p>
                <p class="text-body-2 text-justify">
                    Eudania SA de CV y la aplicación Eudania SP Residentes tiene implementadas las medidas de seguridad técnicas, físicas y administrativas
                    suficientes para evitar la fuga, robo, acceso y tratamiento no autorizado ó perdida de la información.
                </p>
                <p class="text-body-2 text-justify">Solo personal autorizado y capacitado por Eudania SA de CV y la aplicación Eudania SP Residentes para el
                    tratamiento de los datos pueden tener
                    acceso a estos únicamente para consulta y procesos relacionados a los servicios proporcionados por
                    Eudania SA de CV y la aplicación Eudania SP Residentes, y por ningún motivo sin su autorización pueden ser modificados ni presentados a
                    personas ajenas a Eudania SA de CV y la aplicación Eudania SP Residentes.
                </p>
                <p class="text-body-2 text-justify">
                    Esta obligación de confidencialidad persiste aún después de que el personal encargado del manejo de los
                    datos personales haya terminado la relación laboral con Eudania SA de CV y la aplicación Eudania SP Residentes.
                </p>


                <p class="text-h6 font-weight-medium">
                    Derechos ARCO
                </p>
                <p class="text-body-2 text-justify">
                    En caso de que desee acceder, rectificar, cancelar u oponerse a ciertos datos personales que conforman
                    la
                    base de datos de Eudania SA de CV y la aplicación Eudania SP Residentes, puede ponerse en contacto con nosotros a través de las siguientes
                    opciones:
                </p>

                <ul>
                    <li>Correo electrónico: contacto@eudania.com</li>
                    <li>Oficina: Av. Samarkanda 306. Col Tabasco 2000.
                    </li>
                </ul>

                <p class="text-h6 font-weight-medium">
                    Transferencia de Datos
                </p>
                <p class="text-body-2 text-justify">
                    Eudania SA de CV y la aplicación Eudania SP Residentes puede revelar la información personal entre sus afiliados de negocios y terceros
                    proveedores de servicios que actúen a favor de Eudania SA de CV y la aplicación Eudania SP Residentes siempre y cuando haya una necesidad
                    imperante relacionada con los fines para los cuales le fueron solicitados y debido a la naturaleza de
                    los
                    servicios proporcionados por Eudania SA de CV y la aplicación Eudania SP Residentes, la información personal que proporcione puede ser
                    transmitida, usada, almacenada y por otra parte procesada fuera del país donde fue recopilada.
                </p>


                <p class="text-h6 font-weight-medium">
                    Cambios en el Aviso de Privacidad
                </p>

                <p>
                    Nos reservamos el derecho de efectuar en cualquier momento modificaciones o actualizaciones al presente
                    aviso de privacidad, para la atención de novedades legislativas, políticas internas o nuevos
                    requerimientos
                    para la prestación u ofrecimiento de nuestros servicios o productos.
                </p>

            </v-col>
        </v-row>

    </v-container>
</template>
<script>
export default {

}
</script>
<style>
p {
    margin-top: 15px;
}

ul {
    margin-top: 15px;


}

ul>li {
    margin-left: 10px;
}
</style>