<template>
  <v-row>
    <v-carousel cycle height="650" hide-delimiter-background show-arrows-on-hover>
      <v-carousel-item src="@/assets/images/carousel/3.jpg" cover></v-carousel-item>

      <v-carousel-item src="@/assets/images/carousel/4.jpg" cover></v-carousel-item>
      <v-carousel-item src="@/assets/images/carousel/5.jpg" cover></v-carousel-item>

      <v-carousel-item src="@/assets/images/carousel/6.jpg" cover></v-carousel-item>
    </v-carousel>
  </v-row>
  <v-row class="bg-brown">
    <v-container class="mt-7 pt-5 pb-5">
      <v-row>
        <v-col cols="12" md="12">
          <p class="white--text pt-5 pb-5 text-h4">Ofrecemos servicios de seguridad privada y monitoreo para el sector
            público y
            privado.</p>
        </v-col>
        <v-col cols="12" md="3" v-for="s in services" :key="s.id">
          <div class="container-services">
            <v-avatar color="grey" size="250" class="av-services">
              <v-img cover :src="s.image"></v-img>
            </v-avatar>
            <span class="txt-services">{{ s.title }}</span>
          </div>

        </v-col>
      </v-row>
    </v-container>
  </v-row>
  <v-row class="bg-gay200">
    <v-container class="mt-7 pt-5 pb-5">
      <v-row>
        <v-col cols="12" md="12">
          <p class="pt-5 pb-5 text-h4 text-secondary">En <strong>EUDIANA</strong> Desempeñamos nuestro trabajo bajo un
            código moral de respeto y honestidad.</p>
        </v-col>
        <v-col cols="12" md="4" v-for="v in coorporative" :key="v.index" class="my-5">
          <v-card height="300" elevation="5">


            <v-card-item>
              <v-card-title>{{ v.title }}
              </v-card-title>
            </v-card-item>

            <v-card-text class="text-justify" v-if="v.type != 'list'">
              {{ v.text }}
            </v-card-text>

            <v-card-text class="text-justify" v-else>
              <ul class="ml-5">
                <li v-for="l in v.list" :key="v">
                  {{ l }}
                </li>
              </ul>
            </v-card-text>

          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
  <v-row class="bg-gold">
    <v-container class="mt-7 pt-5 pb-5">
      <v-row>
        <v-col cols="12" md="5">
          <v-card>
            <v-img src="@/assets/images/eudiana_tech.jpg" class="align-end text-white img-element" contain>
              <v-card-title class="text-white">
                <div class="txt-shadow-container">
                  Infraestructura tecnológica avanzada.
                </div>
                
              </v-card-title>
            </v-img>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    coorporative: [
      {
        index: 1,
        title: 'Misión',
        type: "text",
        text: "Ser la mejor empresa de seguridad privada en el mercado, para brindarle a nuestros clientes la seguridad de sus bienes, muebles e inmuebles así como de sus vidas, para esto contamos con la mejor capacitación de nuestros elementos tanto en empresas como en cualquier negocio o casa habitación."
      },
      {
        index: 2,
        title: 'Visión',
        type: "text",
        text: "Nos dirigimos hacer una empresa líder en brindar SEGURIDAD Y PROTECCIÓN eﬁciente con calidad a nuestros clientes, nuestro compromiso es brindarles un mejor servicio capacitando a nuestro personal con responsabilidad y eﬁciencia."
      },
      {
        index: 3,
        title: 'Valores',
        type: "list",
        list: ["Excelencia", "Competencia", "Innovación", "Eﬁciencia", "Compromiso", "Perseverancia", "Rentabilidad", "Calidad", "Honestidad", "Lealtad", "Firmeza"]
      },
    ],
    services: [
      { id: 1, title: 'Residencial', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/1.jpg' },
      { id: 2, title: 'Comercial', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/2.png' },
      { id: 3, title: 'Escolar', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/3.jpg' },
      { id: 4, title: 'Hoteles', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/4.jpg' },
      { id: 5, title: 'Eventos Sociales', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/5.jpg' },
      { id: 6, title: 'Eventos Deportivos', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/6.jpg' },
      { id: 7, title: 'Transporte blindado', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/7.jpg' },
      { id: 8, title: 'Protección ejecutiva', text: 'aaaa', image: 'https://servrsc.com/resources/eudania/8.jpeg' },
    ]
  }),
}
</script>

<style>
.bg-brown {
  background-color: #8a6230;
}

.bg-gay200 {
  background-color: #f3eee5;
}

.bg-gold {
  background-color: #ba9656;
}

.container-services {
  position: relative;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
}

.av-services {
  display: inline-block;
  margin-bottom: 30px;
  line-height: 0;
  max-width: 100%;
}

.txt-services {
  display: block;
  margin: auto;
}
.txt-shadow-container{
  background-color: black;
  opacity: 0.7;
}
</style>
