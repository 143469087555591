<template>
    <v-container>
        <v-row class="mb-5">
            <v-col cols="12" md="12">
                <p class="text-h3 font-weight-bold">Eliminar Cuenta de Residente </p>
                <p class="text-body-2 text-justify">
                    A continuación, se dispone a eliminar su cuenta de Residente para usar la App <strong>Eudania SP
                        Residentes.</strong> Una vez eliminada su cuenta todos sus datos serán eliminados de nuestra Base de
                    Datos y no podrá hacer uso de la aplicación, para crear una
                    nueva deberá contactar con la administración de su Privada o Residencial.
                </p>
                <p>Por favor proporcione su usuario y contraseña, a continuación recibirá un correo electrónico informando
                    de la baja de su cuenta.</p>
            </v-col>
            <v-col cols="12" md="6">
                <v-form ref="form_login">
                    <v-text-field solo v-model="user.email" :rules="[rules.email]" label="Email"
                        prepend-inner-icon="mdi-email" clearable></v-text-field>
                    <v-text-field type="password" label="Password" v-model="user.password"
                        @keyup.enter="handdleDeleteAccount" prepend-inner-icon="mdi-account-lock" solo></v-text-field>
                    <p>Nos gustaría conocer el motivo de tu baja o algún comentario que nos pueda ayudar a mejorar nuestros
                        servicios.</p>
                    <v-textarea v-model="user.reasons" auto-grow filled label="Motivos de baja" rows="4"></v-textarea>
                    <v-btn block dark :loading="loading" :disabled="loading" color="black" @click="handdleDeleteAccount">
                        Eliminar mi cuenta
                    </v-btn>
                </v-form>
            </v-col>
            <v-col cols="6" md="3">
                <v-img src="@/assets/images/1.png" contain width="200"></v-img>
            </v-col>
            <v-col cols="6" md="3">
                <v-img src="@/assets/images/2.png" contain width="200"></v-img>
            </v-col>
        </v-row>

    </v-container>
</template>
<script>

import 'animate.css';
import Swal from 'sweetalert2'
import { useAccountStore } from '@/stores/accounts.js'


export default {
    data: () => ({
        loader: null,
        loading: false,
        user: {},
        rules: {
            email: (v) => !!(v || "").match(/@/) || "Ingrese un email válido",
            length: (len) => (v) =>
                (v || "").length >= len || `Longitud no válida, requerida ${len}`,
            password: (v) =>
                !!(v || "").match(
                    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(_|[^\w])).+$/
                ) ||
                "La contraseña debe contener una letra mayúscula, un carácter numérico y un carácter especial",
            required: (v) => !!v || "Este campo es requerido",
        },
    }),
    methods: {
        handdleDeleteAccount() {
            const store = useAccountStore();
            this.loader = "validando";
            store.removeAccount(this.user).then(rs => {
                if (rs == 200) {
                    this.user = {}
                    Swal.fire({
                        showClass: {
                            popup: `
                    animate__animated
                    animate__fadeInUp
                    animate__faster
                    `
                        },
                        hideClass: {
                            popup: `
                    animate__animated
                    animate__fadeOutDown
                    animate__faster
    `
                        },
                        position: "top-end",
                        icon: "success",
                        title: "Su cuenta ha sido eliminada.",
                        showConfirmButton: false,
                        timer: 1900
                    })
                }
            })
        }
    },
    watch: {
        loader() {
            const l = this.loader;
            this[l] = !this[l];

            setTimeout(() => (this[l] = false), 3000);

            this.loader = null;
        },
    }
}
</script>
<style>
p {
    margin-top: 15px;
}

ul {
    margin-top: 15px;


}

ul>li {
    margin-left: 10px;
}
</style>