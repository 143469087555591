<template>
  <v-app>
    <v-system-bar>
      <v-icon class="ml-5 mr-2">mdi-facebook</v-icon>
      <v-spacer></v-spacer>

      <v-icon class="mr-2">mdi-phone</v-icon>
      <span class="mr-5">9934780288</span>

      <v-icon class="mr-2">mdi-email</v-icon>
      <span class="mr-5">contacto@eudania.com</span>

      <v-icon class="mr-2">mdi mdi-map-marker</v-icon>
      <span class="mr-5">Avenida Samarkanda 306 Piso 4 Col. Tabasco 2000 CP 86035.</span>
    </v-system-bar>

    <v-app-bar>
      <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon>

      <v-app-bar-title>
        <v-img contain src="@/assets/images/eudania_logo.png" width="133"/>
      </v-app-bar-title>
      <span class="text-primary text-h5 font-weight-bold">SERVICIOS DE SEGURIDAD PRIVADA</span>
      <v-spacer></v-spacer>
    </v-app-bar>

    <v-navigation-drawer v-model="drawer" temporary>
      <!--  -->
    </v-navigation-drawer>

    <v-main>

      <router-view />

    </v-main>
    <eudania-footer />
    <div>
      <div v-if="showChat" class="chat-container">
        <v-sheet elevation="12" max-width="600" rounded="lg" width="100%" class="pa-4 text-center mx-auto">
          <v-icon class="mb-5" color="success" icon="mdi mdi-forum" size="30"></v-icon>

          <h2 class="text-h6 mb-6">Su Seguridad es nuestro compromiso</h2>

          <p class="mb-4 text-medium-emphasis text-body-2">
            Estamos listos para atenderle
          </p>
          <v-textarea label="Mensaje de whatsapp" auto-grow variant="outlined" rows="3" row-height="25" shaped v-model="message"></v-textarea>
          <v-divider class="mb-4"></v-divider>

          <div class="text-end">
            <v-btn class="text-none" color="success" rounded variant="flat" width="90"  @click="sendMessage">
              Enviar
            </v-btn>
          </div>
        </v-sheet>
        <!-- <textarea v-model="message" placeholder="Escribe tu mensaje aquí"></textarea>
        <button  @click="sendMessage">Enviar</button> -->
      </div>
      <v-btn color="whatsapp" icon="mdi mdi-whatsapp" size="large" class="floating-button" @click="toggleChat"></v-btn>
    </div>
  </v-app>
</template>

<script>
import { ref } from 'vue'
import EudaniaFooter from './components/EudaniaFooter.vue';
const drawer = ref(null)
export default {
  name: 'App',
  data: () => ({
    drawer: null,
    direction: 'top',
    fab: false,
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: true,
    bottom: true,
    left: false,
    transition: 'slide-y-reverse-transition',
  }),
  components: { EudaniaFooter },
  data: () => ({
    showChat: false,
    number: '9933891777',
    message: ''
  }),
  methods: {
    toggleChat() {
      this.showChat = !this.showChat;
    },
    sendMessage() {
      const url = `https://api.whatsapp.com/send?phone=${this.number}&text=${encodeURIComponent(this.message)}`;
      window.open(url);
    }
  }
}
</script>
<style>
.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  border-radius: 50%;
  width: 60px;
  height: 60px;
}

.chat-container {
  position: fixed;
  bottom: 100px;
  right: 20px;
  width: 300px;
}
</style>