<template>
  <v-container fluid class="footer-container">
    <v-snackbar :timeout="4000" color="tertiary" rounded="pill" v-model="downloadSnackbar">
      <div class="container-eudania-snack">
        <v-icon class="snack-icon" color="success" icon="mdi mdi-android" size="60"></v-icon>
        <span class="snack-txt mt-n7">Esta descarga solo es válida en un dispositivo movil con <strong>Android</strong>.</span>
      </div>
    </v-snackbar>

    <v-row class="pa-7">
      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Télefono</p>
        <p class="text-body-1 text-left"><i class="mdi mdi-phone"></i> 9934780288 </p>

      </v-col>
      <v-col cols="12" md="4" class="info-section">
        <p class="text-subtitle-1"> Nuestras Sucursales</p>
        <p class="text-body-2"><i class="mdi mdi-map-marker"></i> Avenida Samarkanda 306 Piso 4 Col. Tabasco 2000 CP 86035</p>

      </v-col>


      <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Solicita información</p>
        <p class="text-body-3"><i class="mdi mdi-email-outline"></i> Envianos un email para mas información o para
          aclaraciones: contacto@eudania.com</p>
      </v-col>
      <!-- <v-col cols="12" md="3" class="info-section">
        <p class="text-subtitle-1"> Folletos digitales</p>
        <p class="text-body-3"><i class="mdi mdi-file-table"></i> Consulta los folletos informativos</p>
      </v-col> -->
      <v-col cols="12" md="12">
        <v-divider dark></v-divider>
      </v-col>
    </v-row>
    <v-row no-gutters>

      <v-col cols="12" md="3" class="pa-5">
        <v-img src="@/assets/images/eudania_logo.png" alt="footer logo" width="250" />
        <span class="text-subtitle-2 text-left">A través de nuestro personal capacitado proporcionamos soluciones
          integrales de seguridad a hogares, empresas privadas y gubernamentales.</span>
      </v-col>
      <v-col cols="12" md="6" class="pa-5">
        <v-list dark class="text-left">
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Servicios</v-list-item-title>
              <v-list-item-subtitle>
                Innovamos en la Seguridad Privada, logrando altos estándares de áalidad para nuestros clientes.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Socio comercial</v-list-item-title>
              <v-list-item-subtitle>
                Tenemos a tu alcance planes para asociarte y dar servicios.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Terminos & Condiciones</v-list-item-title>
              <v-list-item-subtitle>
                Todos tus bienes, instalaciones y personal están seguros.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line to="/aviso_privacidad">
            <v-list-item-content>
              <v-list-item-title>Aviso de privacidad</v-list-item-title>
              <v-list-item-subtitle>
                Tu seguridad y confidencialidad de tus datos los tomamos muy enserio.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line to="/info_account">
            <v-list-item-content>
              <v-list-item-title>Cuentas de Residentes</v-list-item-title>
              <v-list-item-subtitle>
                ¿Cómo obtener una cuenta de Residente para la app Eudania SP Residentes?
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line to="/remove_account">
            <v-list-item-content>
              <v-list-item-title>Baja de cuenta</v-list-item-title>
              <v-list-item-subtitle>
                Elimina tu cuenta de la aplicacion Eudania Residentes
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-list-item two-line>
            <v-list-item-content>
              <v-list-item-title>Soluciones en Seguridad Privada</v-list-item-title>
              <v-list-item-subtitle>
                Conoce nuestra amplia gama de servicios.
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list>

      </v-col>
      <v-col cols="12" md="3" class="pa-5">
        <div class="single-footer-widget">
          <h3>Newsletter</h3>
          <p>No olvides suscribirte para recibir noticias, promociones e infromación, por favor llene
            el siguiente formulario.
            .</p>
          <form>
            <input type="email" placeholder="Email">
            <button type="submit"><i class="far fa-envelope"></i></button>
          </form>
        </div>
        <div class="pt-5">
          <v-btn prepend-icon="mdi mdi-android" block size="x-small" color="whatsapp" @click="downloadFile">
            Descargar App
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

export default {
  name: 'EudaniaFooter',

  data: () => ({
    downloadSnackbar: false,
    importantLinks: [
      {
        text: 'Chat',
        href: 'https://community.vuetifyjs.com',
      },
      {
        text: 'Made with Vuetify',
        href: 'https://madewithvuejs.com/vuetify',
      },
      {
        text: 'Twitter',
        href: 'https://twitter.com/vuetifyjs',
      },
      {
        text: 'Articles',
        href: 'https://medium.com/vuetify',
      },
    ]
  }),
  methods: {
    downloadFile() {
      const isMobile = /Android/i.test(navigator.userAgent);
      if (isMobile) {
        const link = document.createElement('a');
        link.href = "https://play.google.com/store/apps/details?id=com.reinscom.eudaniasp&pcampaignid=web_share"
        //link.href = 'https://servrsc.com/resources/eudania/eudania-sp.apk';
        link.download = 'eudania-sp.apk';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
      else {
        this.downloadSnackbar = true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-container {
  background-color: #1e1e1e;
  color: #c7baba;
}

.mdi {
  font-size: 30px;
  margin-right: 5px;
}

.far {
  font-size: 18px;
}

.single-footer-widget li {
  margin-bottom: 5px
}

.single-footer-widget li a:hover {
  color: #eee
}

.single-footer-widget>form {
  background: #333 none repeat scroll 0 0;
  height: 35px;
  margin-top: 10px;
  position: relative;
}

.single-footer-widget input {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  height: 100%;
  left: 0;
  padding: 5px 10px;
  position: absolute;
  top: 0;
  color: #eee;
  width: 80%;
}

.single-footer-widget button {
  background: #ba9656 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 100%;
  position: absolute;
  right: 0;
  top: 0;
  width: 20%;
  -webkit-transition: all 0.4s ease 0s;
  transition: all 0.4s ease 0s
}

.single-footer-widget button:hover {
  background: #464646 none repeat scroll 0 0
}

.v-list {
  background-color: #1e1e1e !important;
  color: #c7baba !important;
}
.container-eudania-snack{
  position: relative;
  text-align: center;
  word-wrap: break-word;
  width: 100%;
}
.snack-icon {
  display: inline-block;
  line-height: 0;
  max-width: 100%;
}

.snack-txt {
  display: block;
  margin: auto;
}
</style>
