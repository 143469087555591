// Styles
import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/styles'

// Vuetify
import { createVuetify } from 'vuetify'

export default createVuetify({
  theme: {
    defaultTheme: 'eudaniaTheme',
    themes: {
      eudaniaTheme: {
        dark: false,
        variables: {},
        colors: {
          primary: '#010546',
          secondary: '#8a6230',
          tertiary: '#f3eee5',
          gold: '#ba9656',
          blackeudania: '#1e1e1e',
          whatsapp: '#4caf50'
        }
      }
    }
  }
}
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides

)
