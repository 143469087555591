import { defineStore } from 'pinia'
import repositoryService from '@/stores/services/repository.services'



export const useAccountStore = defineStore("account", {
    state: () => ({
        isBussy: false
    }),
    actions:{
        removeAccount(data){
            try {                
                let entity = process.env.VUE_APP_REMOVE_USER_ACCOUNT;
                return repositoryService.post(data, entity).then(
                    response =>{
                        return Promise.resolve(response.status);
                    }, ()=>{
                        return Promise.reject(false);
                    }
                )
            } catch (error) {
                console.error(error);
            }
        }
    }
})